<template>
	<ContactSalesDialog btnType="secure" />
</template>

<script>
import ContactSalesDialog from "@/components/dialogs/contact-sales-dialog/ContactSalesDialog";
export default {
	name: "GetSecureLeftBtn",
	components: {
		ContactSalesDialog,
	},
	// methods: {
	// 	goToPage() {
	// 		if (this.$route.name !== "WelcomePage") {
	// 			this.$router.push({
	// 				name: "WelcomePage",
	// 			});
	// 		}
	// 	},
	// },
};
</script>

<style lang="scss" scoped>
.black-btn {
	border-radius: 30px !important;
	background: var(--v-black-base) !important;
	color: white !important;
	transition: all 0.4s;
	min-width: 190px !important;

	&:hover {
		background: rgba($color: #000000, $alpha: 0.9) !important;
	}
}
@media #{map-get($display-breakpoints, 'sm-and-down')} {
	.black-btn {
		min-width: 170px !important;
	}
}
</style>
