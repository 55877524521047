import SubheaderBtn from '@/components/custody-solution/custody-solution-buttons/SubheaderBtn'
import Charts from '@/components/image-components/Charts'
import CustomList from '@/components/custom-list/CustomList.vue'

export default {
  name: 'Subheader',
  components: {
    SubheaderBtn,
    Charts,
		CustomList
  },
	computed: {
		list () {
			return [
				this.trans('custody_solution_page_preview_list_item_1'),
				this.trans('custody_solution_page_preview_list_item_2'),
				this.trans('custody_solution_page_preview_list_item_3')
			]
		}
	}
}
